<!--服务介绍-->
<template>
  <el-container class="layout-container service-intro-container">
    <el-header class="service-intro-header">
      <div style="margin-left: 196px;">
        <img src="../../../assets/images/logo-pdBox.png" />
        <span class="header-text">服务介绍</span>
      </div>
      <el-button type="text" @click="toLoginHandle" class="header-btn back-login-btn">返回登录</el-button>
    </el-header>
    <el-main class="service-intro-main" ref="serviceMainRef">
      <!-- <transition name="component-fade" mode="out-in">
        <component :is="which_to_show" @componentChange="changeHandle"></component>
      </transition> -->
      <el-table
        :data="tableData"
        :header-cell-class-name="headerCellClass"
        :header-row-style="headerRowStyle"
        :cell-class-name="cellClass"
        :span-method="objectSpanMethod"
        :border="false"
        id="service-intro-table"
        :class="tableClass">
        <el-table-column
          align="center"
          label="服务项目">
          <el-table-column align="center" width="130">
            <template slot-scope="scope">
              <span>{{ scope.row.projectName }}</span>
              <el-popover
                title=""
                width="400"
                trigger="hover"
                :content="tipsStr(scope.row)"
                :fallback-placements="['bottom', 'top', 'right', 'left']">
                <el-button
                  v-if="scope.row.projectNameShowTips"
                  slot="reference"
                  type="text"
                  class="tips-btn">
                  <svg-icon
                    icon-class="question-icon"
                    fill-color="white" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.detailName }}</span>
            </template>
          </el-table-column>
          <!-- <template slot="header" slot-scope="scope">
            <span>服务项目</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.projectName }}</span>
          </template> -->
        </el-table-column>
        <!-- <el-table-column
          align="center"
          width="188">
          <template slot="header" slot-scope="scope">
            <span></span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.detailName }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          align="center"
          >
          <template slot="header" slot-scope="scope">
            <span>体验用户</span>
            <!-- <span style="display: block;font-size: 14px;margin-top: 10px;">（免费）</span> -->
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.expUser }}</span>
            <el-popover
              title=""
              
              trigger="hover"
              :content="tipsStr(scope.row)"
              :fallback-placements="['bottom', 'top', 'right', 'left']">
              <el-button
                v-if="showExpFieldTips(scope.row) || showExpDataTips(scope.row)"
                slot="reference"
                type="text"
                class="tips-btn">
                <svg-icon
                  icon-class="question-icon" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          >
          <template slot="header" slot-scope="scope">
            <span>标准用户</span>
            <svg-icon icon-class="standa-user" fill-color="#fff" />
            <!-- <p>（不支持多院区，3W/年）</p> -->
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.standardUser }}</span>
            <el-popover
              title=""
              
              trigger="hover"
              :content="tipsStr(scope.row)"
              :fallback-placements="['bottom', 'top', 'right', 'left']">
              <el-button
                v-if="showStandardFieldTips(scope.row) || showStandardDataTips(scope.row)"
                slot="reference"
                type="text"
                class="tips-btn">
                <svg-icon
                  icon-class="question-icon" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          >
          <template slot="header" slot-scope="scope">
            <span>专业用户</span>
            <svg-icon icon-class="pro-user" fill-color="#fff" />
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.professionalUser }}</span>
            <el-popover
              title=""
              trigger="hover"
              :content="tipsStr(scope.row)"
              :fallback-placements="['bottom', 'top', 'right', 'left']">
              <el-button
                v-if="showProFieldTips(scope.row) || showProDataTips(scope.row)"
                slot="reference"
                type="text"
                class="tips-btn">
                <svg-icon
                  icon-class="question-icon"
                  fill-color="white" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          >
          <template slot="header" slot-scope="scope">
            <span>超级用户</span>
            <svg-icon icon-class="super-user" fill-color="#E59F25" />
            <span style="display: block;margin-top: 4px;font-size: 14px;">（支持多院区）</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.superUser }}</span>
            <el-popover
              title=""
              
              trigger="hover"
              :content="tipsStr(scope.row)"
              :fallback-placements="['bottom', 'top', 'right', 'left']">
              <el-button
                v-if="showSuperFieldTips(scope.row) || showSuperDataTips(scope.row)"
                slot="reference"
                type="text"
                class="tips-btn">
                <svg-icon
                  icon-class="question-icon"
                  fill-color="white" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="service-intro-footer">
      <div>
        <img src="../../../assets/images/icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
        <span style="padding-left:5px;">{{ opennessConfig.webSystemLatestVersion }}</span>
      </div>
      <div>
        <img src="../../../assets/images/beian-icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620"
        style="display:inline-block;text-decoration:none;">
        <p style="padding-left:5px;color:#939393;">沪公网安备 31010602006620号</p></a>
      </div>
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank" style="padding: 0;" class="baNum">沪ICP备20018067号-2</a>
      </div>
      <div><span style="font-size: 18px;vertical-align: middle;">©</span>2022 上海卫心科技有限公司</div>
      <div>服务协议</div>
      <div>法律声明及隐私权政策</div>
    </el-footer>
    <!--客服-->
    <div class="cust-service-wrap">
      <header class="cust-service-header">
        <svg-icon icon-class="ke-fu" fill-color="#668FFF" />
        <span style="margin-left: 5px;">添加客服咨询</span>
      </header>
      <section class="cust-service-main clearfix">
        <img src="../../../assets//images/kefu-qr.png" class="fl" />
        <div class="cust-service-main-right">
          <p>
            <svg-icon icon-class="phone-icon" fill-color="#A5BCFF" />
            <span class="text">021-80331222</span>
          </p>
          <p>
            <svg-icon icon-class="email-user" fill-color="#A5BCFF" />
            <span class="text">service@ivisense.com</span>
          </p>
        </div>
      </section>
    </div>
  </el-container>
  </template>
  <script>
    import { opennessConfigList } from "@/api/system/config"
  export default {
    name: 'ServiceIntro',
    components: {
    },
    data() {
      return {
        /*系统开发配置信息*/
        opennessConfig:{
          /*安卓app下载二维码base64*/
          androidAppDownloadQrCode:"",
          webSystemLatestVersion: '' // pc版本号
        },
        dataGovTipsStr: '上传药品如为新增或信息更新的情况，则计入累计药品数量；上传的药品如无信息更新，则不计入累计数量。',
        allFieldTipsStr: '所有字段包括：基础字段、标准字段和拓展字段。（详情请咨询商务人员）',
        tableData: [
          {
            projectName: '账号管理',
            projectCode: 1,
            detailCode: '1-1',
            detailName: '账号数量',
            detailShowTips: false,
            expUser: '1个', // 体验用户
            standardUser: '1个', // 标准用户
            professionalUser: '5个', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '账号管理',
            projectCode: 1,
            detailCode: '1-2',
            detailName: '时限',
            detailShowTips: false,
            expUser: '30天', // 体验用户
            standardUser: '会员订阅时限', // 标准用户
            professionalUser: '会员订阅时限', // 专业用户
            superUser: '会员订阅时限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },

          {
            projectName: '药品数据治理服务',
            projectCode: 2,
            detailCode: '2-1',
            detailName: '数据治理',
            detailShowTips: false,
            expUser: '1次', // 体验用户
            standardUser: '累计药品数量 5,000条', // 标准用户
            professionalUser: '累计药品数量 10,000条', // 专业用户
            superUser: '累计药品数量 50,000条',
            expShowTips: false,
            standardShowTips: true,
            professionalShowTips: true,
            superShowTips: true,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: true, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '药品数据治理服务',
            projectCode: 2,
            detailCode: '2-2',
            detailName: '药品知识库更新',
            detailShowTips: false,
            expUser: '无', // 体验用户
            standardUser: '1次/月', // 标准用户
            professionalUser: '1次/月', // 专业用户
            superUser: '1次/月', // 超级用户
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },

          {
            projectName: 'WEB',
            projectCode: 3,
            detailCode: '3-1',
            detailName: '数据上传',
            detailShowTips: false,
            expUser: '不限', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'WEB',
            projectCode: 3,
            detailCode: '3-2',
            detailName: '标准数据审核及下载',
            detailShowTips: false,
            expUser: '单次服务（最低500条）', // 体验用户
            standardUser: '基础数据治理', // 标准用户
            professionalUser: '标准数据治理', // 专业用户
            superUser: '高级数据治理', // 超级用户
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'WEB',
            projectCode: 3,
            detailCode: '3-3',
            detailName: '治理报告服务次数',
            detailShowTips: false,
            expUser: '1次', // 体验用户
            standardUser: '4次/年', // 标准用户
            professionalUser: '12次/年', // 专业用户
            superUser: '24次/年',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'WEB',
            projectCode: 3,
            detailCode: '3-4',
            detailName: '治理报告服务范围',
            detailShowTips: false,
            expUser: '所有字段', // 体验用户
            standardUser: '基础数据', // 标准用户
            professionalUser: '标准数据', // 专业用户
            superUser: '所有字段', // 超级用户
            expShowTips: true,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: true,
            showAllField: true, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },

          {
            projectName: '小程序',
            projectCode: 4,
            detailCode: '4-1',
            detailName: '扫描“条形码”检索药品',
            detailShowTips: false,
            expUser: '限10次', // 体验用户
            standardUser: '200次/月', // 标准用户
            professionalUser: '500次/月', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '小程序',
            projectCode: 4,
            detailCode: '4-2',
            detailName: '药盒拍照“OCR”检索药品',
            detailShowTips: false,
            expUser: '限10次', // 体验用户
            standardUser: '200次/月', // 标准用户
            professionalUser: '500次/月', // 专业用户
            superUser: '不限', // 超级用户
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '小程序',
            projectCode: 4,
            detailCode: '4-3',
            detailName: '输入条件检索药品',
            detailShowTips: false,
            expUser: '不限', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '小程序',
            projectCode: 4,
            detailCode: '4-4',
            detailName: '查看治理报告',
            detailShowTips: false,
            expUser: '不限', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限', // 超级用户
            expShowTips: true,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: true,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '小程序',
            projectCode: 4,
            detailCode: '4-5',
            detailName: '添加待入库药品',
            detailShowTips: false,
            expUser: '—', // 体验用户
            standardUser: '200条/月', // 标准用户
            professionalUser: '500次/月', // 专业用户
            superUser: '3,000条/月', // 超级用户
            expShowTips: true,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: true,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },

          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-1',
            detailName: '药品元数据与字典表上传',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-2',
            detailName: '查询当前数据治理状态',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-3',
            detailName: '标准药品元数据发布通知',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-4',
            detailName: '查询标准药品元数据发布列表',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-5',
            detailName: '更新入库药品状态',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-6',
            detailName: '查询待入库药品列表',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '不限', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-7',
            detailName: '标准药品元数据下载',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '基础数据治理', // 标准用户
            professionalUser: '标准数据治理', // 专业用户
            superUser: '高级数据治理', // 超级用户
            expShowTips: true,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: true,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: 'API',
            projectDesc: '',
            projectCode: 5,
            detailCode: '5-8',
            detailName: '添加待入库药品',
            projectNameShowTips: true,
            detailShowTips: true,
            expUser: '—', // 体验用户
            standardUser: '200条/月', // 标准用户
            professionalUser: '500条/月', // 专业用户
            superUser: '3,000条/月', // 超级用户
            expShowTips: true,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: true,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },

          {
            projectName: '移动端APP',
            projectDesc: '（目前仅支持安卓）',
            projectCode: 6,
            detailCode: '6-1',
            detailName: '扫描“条形码”检索药品',
            detailShowTips: false,
            expUser: '—', // 体验用户
            standardUser: '—', // 标准用户
            professionalUser: '500次/月', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '移动端APP',
            projectDesc: '（目前仅支持安卓）',
            projectCode: 6,
            detailCode: '6-2',
            detailName: '药盒拍照“OCR”检索药品',
            detailShowTips: false,
            expUser: '—', // 体验用户
            standardUser: '—', // 标准用户
            professionalUser: '500次/月', // 专业用户
            superUser: '不限', // 超级用户
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '移动端APP',
            projectDesc: '（目前仅支持安卓）',
            projectCode: 6,
            detailCode: '6-3',
            detailName: '输入条件检索药品',
            detailShowTips: false,
            expUser: '—', // 体验用户
            standardUser: '—', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限',
            expShowTips: false,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: false,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '移动端APP',
            projectDesc: '（目前仅支持安卓）',
            projectCode: 6,
            detailCode: '6-4',
            detailName: '查看治理报告',
            detailShowTips: false,
            expUser: '—', // 体验用户
            standardUser: '—', // 标准用户
            professionalUser: '不限', // 专业用户
            superUser: '不限', // 超级用户
            expShowTips: true,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: true,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
          {
            projectName: '移动端APP',
            projectDesc: '（目前仅支持安卓）',
            projectCode: 6,
            detailCode: '6-5',
            detailName: '添加待入库药品',
            detailShowTips: false,
            expUser: '—', // 体验用户
            standardUser: '—', // 标准用户
            professionalUser: '500条/月', // 专业用户
            superUser: '3,000条/月', // 超级用户
            expShowTips: true,
            standardShowTips: false,
            professionalShowTips: false,
            superShowTips: true,
            showAllField: false, // 是否显示所有字段的冒泡框
            showDataGovber: false, // 是否显示数据治理的冒泡狂
          },
        ],
        spanArr: [],
        pos: 0,
        isFixed: false
      }
    },
    computed: {
      showExpFieldTips() {
        // 体验用户是否显示所有字段的tips
        return function (row) {
          if (row.showAllField && row.expShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      showExpDataTips() {
        // 体验用户是否显示关于数据治理的tips
        return function (row) {
          if (row.showDataGovber && row.expShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      showStandardFieldTips() {
        // 标准用户是否显示所有字段的tips
        return function (row) {
          if (row.showAllField && row.standardShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      showStandardDataTips() {
        // 标准用户是否显示关于数据治理的tips
        return function (row) {
          if (row.showDataGovber && row.standardShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      showProFieldTips() {
        // 专业用户是否显示所有字段的tips
        return function (row) {
          if (row.showAllField && row.professionalShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      showProDataTips() {
        // 专业用户是否显示关于数据治理的tips
        return function (row) {
          if (row.showDataGovber && row.professionalShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      showSuperFieldTips() {
        // 超级用户是否显示所有字段的tips
        return function (row) {
          if (row.showAllField && row.superShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      showSuperDataTips() {
        // 超级用户是否显示关于数据治理的tips
        return function (row) {
          if (row.showDataGovber && row.superShowTips) {
            return true
          } else {
            return false
          }
        }
      },
      tipsStr() {
        return function (row) {
          if (row.showDataGovber) {
            return this.dataGovTipsStr
          }
          if (row.showAllField) {
            return this.allFieldTipsStr
          }
          if (row.projectNameShowTips) {
            return 'API指数据治理平台按照接口文档提供的软件功能；不包括机构端院内系统的接入和改造部分。'
          }
        }
      },
      tableClass() {
        if (this.isFixed) {
          return 'header-fixed-table'
        } else {
          return ''
        }
      }
    },
    watch: {
    },
    created() {
      this.getOpennessConfig()
      this.handleSpanArr()
    },
    mounted(){
      this.$nextTick(() => {
        // document.addEventListener('scroll', this.handleScroll)
        const dom = this.$refs.serviceMainRef.$el
        dom.addEventListener('scroll', this.handleScroll)
      })
    　　
      // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    },
    beforeDestroy () {
      this.$refs.serviceMainRef.$el.removeEventListener("scroll", this.listenerFunction);
    },
    methods: {
      handleScroll() {
        // 滚动条偏移量
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // 要滚动到顶部吸附的元素的偏移量
        let offsetTop = document.querySelector('#service-intro-table').offsetTop
        // 如果滚动到顶部了，this.isFixed就为true
        // console.log(offsetTop)
        const domScrollTop = this.$refs.serviceMainRef.$el.scrollTop
        // this.isFixed = scrollTop > offsetTop ? true : false
        if (domScrollTop >= 20) {
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      },
      /*获取系统开放配置信息*/
      getOpennessConfig(){
        opennessConfigList().then(res=>{
          this.opennessConfig = res.data
        })
      },
      headerRowStyle({row, column, rowIndex, columnIndex}) {
        if (rowIndex === 1) {
          //这里为了是将第二列的表头隐藏，就形成了合并表头的效果
          return {background: 'red', display: 'none'}
        }
        // return {background: '#E8E9ED'}
      },
      headerCellClass({row, column, rowIndex, columnIndex}) {
        if (columnIndex === 0) {
          // return 'no-borde-left-cell'
          // return {
          //   borderRight: 'none', height: '72px',backgroundColor: '#E8E9ED'
          // }
          return 'no-border-right-cell header-cell'
        } else if (columnIndex === 1){
          return 'exp-header-cell header-cell'
        } else if (columnIndex === 2){
          return 'standard-header-cell header-cell'
        } else if (columnIndex === 3){
          return 'professional-header-cell header-cell'
        } else if (columnIndex === 4){
          return 'super-header-cell header-cell'
        }
      },
      cellClass({row, column, rowIndex, columnIndex}) {
        if (columnIndex === 0 || columnIndex === 1) {
          return 'gray-cell'
        }
        if (columnIndex > 1 && rowIndex % 2 === 1) {
          return 'stripe-cell'
        }
      },
      handleSpanArr() {
        for (let i = 0; i < this.tableData.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            // 判断当前元素与上一个元素是否相同
            //这里的groupName是第一列的属性，我用来判断是否相同。
            if (this.tableData[i].projectCode === this.tableData[i - 1].projectCode) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
        // console.log(this.spanArr)
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (rowIndex === -1 && columnIndex === 1) {
          // return {
          //   rowspan: 0,
          //   colspan: 1
          // }
          return [1, 2]
        }
        if (columnIndex === 0) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          // console.log(_row)
          // console.log(_col)
          return {
            // _row = 0，_col = 0 表示这一次不合并，不显示，
            // _row = 2，_col = 1 表示这一次合并第一列的两行
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      // 返回登录
      toLoginHandle() {
        this.$router.push('/login')
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .baNum:hover{
    text-decoration:underline;
  }
  .el-table--group::after, .el-table--border::after {
    width: 0px;
  }
  /* ::v-deep.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #F3F6FF;
  } */
  .service-intro-container {
    .service-intro-header {
      position: relative;
      height: 72px !important;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px 0px rgba(189, 192, 199, 0.7);
      img {
        vertical-align: middle;
      }
      .header-text {
        display: inline-block;
        border-left: 2px solid #C8D9FA;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-left: 10px;
        padding-left: 10px;
      }
      .back-login-btn {
        position: absolute;
        right: 208px;
      }
    }
    .service-intro-main {
      padding: 20px 196px;
      ::v-deep .header-fixed-table {
        .el-table__header-wrapper {
          position: fixed;
          width: 100%;
          height: 72px;
          z-index: 2;
          top: 0px;
          left: 0;
          box-shadow: 0px 4px 6px 0px rgba(204, 204, 204, 0.72);
          .el-table__header {
            position: fixed;
            left: 196px;
          }
        }
      }
      ::v-deep.el-table {
        
        border-top: none;
        border: none;
        /* width: calc(100% - 360px);
        margin: 0 0 0 50px; */
        .no-border-right-cell {
          border-right: none !important;
          /* height: 72px; */
          background: #E8E9ED;
          border-radius: 10px 10px 0px 0px;
        }
        .gray-cell {
          background-color: #F8F8F8;
        }
        .stripe-cell {
          background-color: #F3F6FF;
        }
        .header-cell {
          font-size: 18px;
          height: 72px;
        }
        .exp-header-cell {
          background-image: url('../../../assets/images/exp.png');
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px 10px 0px 0px;
          color: #fff;
          /* height: 72px; */
          border-right: none;
        }
        .standard-header-cell {
          background-image: url('../../../assets/images/standard.png');
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px 10px 0px 0px;
          color: #fff;
          /* height: 72px; */
          border-right: none;
        }
        .professional-header-cell {
          background-image: url('../../../assets/images/pro.png');
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px 10px 0px 0px;
          color: #fff;
          /* height: 72px; */
          border-right: none;
        }
        .super-header-cell {
          background-image: url('../../../assets/images/super.png');
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px 10px 0px 0px;
          color: #E59F25;
          /* height: 72px; */
          border-right: none;
        }
        .el-table__cell.gutter {
          background-color: transparent;
        }
        .tips-btn {
          margin-left: 5px;
        }
      }
    }
    .service-intro-footer {
      height: 40px!important;
      line-height: 40px;
      width: 100%;
      height: 40px;
      background:#EBEFF7;
      opacity: 0.8;
      /* position: fixed; */
      display: flex;
      justify-content: center;
      bottom: 0;
      width: 100%;
      text-align: center;
      color: #999999;
      font-family: Arial;
      font-size: 14px;
      letter-spacing: 1px;
      div {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .cust-service-wrap {
      position: fixed;
      width: 180px;
      right: 20px;
      top: 50%;
      background: #FFFFFF;
      border: 1px solid #CCDAFF;
      border-radius: 6px;
      .cust-service-header {
        height: 40px;
        background: #E6EDFF;
        padding-left: 14px;
        line-height: 40px;
      }
      .cust-service-main {
        padding-left: 14px;
        position: relative;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          box-shadow: 0px 0px 6px 0px rgba(148, 156, 197, 0.8);
          width: 76px;
          height: 76px;
        }
        .cust-service-main-right {
          /* position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%); */
          font-size: 14px;
          color: #666666;
          margin-top: 20px;
          .text {
            margin-left: 5px;
          }
        }
      }
    }
  }
  
  </style>
  