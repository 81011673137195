var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container service-intro-container" },
    [
      _c(
        "el-header",
        { staticClass: "service-intro-header" },
        [
          _c("div", { staticStyle: { "margin-left": "196px" } }, [
            _c("img", {
              attrs: { src: require("../../../assets/images/logo-pdBox.png") },
            }),
            _c("span", { staticClass: "header-text" }, [_vm._v("服务介绍")]),
          ]),
          _c(
            "el-button",
            {
              staticClass: "header-btn back-login-btn",
              attrs: { type: "text" },
              on: { click: _vm.toLoginHandle },
            },
            [_vm._v("返回登录")]
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { ref: "serviceMainRef", staticClass: "service-intro-main" },
        [
          _c(
            "el-table",
            {
              class: _vm.tableClass,
              attrs: {
                data: _vm.tableData,
                "header-cell-class-name": _vm.headerCellClass,
                "header-row-style": _vm.headerRowStyle,
                "cell-class-name": _vm.cellClass,
                "span-method": _vm.objectSpanMethod,
                border: false,
                id: "service-intro-table",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "服务项目" } },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", width: "130" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.projectName))]),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  title: "",
                                  width: "400",
                                  trigger: "hover",
                                  content: _vm.tipsStr(scope.row),
                                  "fallback-placements": [
                                    "bottom",
                                    "top",
                                    "right",
                                    "left",
                                  ],
                                },
                              },
                              [
                                scope.row.projectNameShowTips
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "tips-btn",
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "question-icon",
                                            "fill-color": "white",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.detailName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("体验用户")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.expUser))]),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: "",
                              trigger: "hover",
                              content: _vm.tipsStr(scope.row),
                              "fallback-placements": [
                                "bottom",
                                "top",
                                "right",
                                "left",
                              ],
                            },
                          },
                          [
                            _vm.showExpFieldTips(scope.row) ||
                            _vm.showExpDataTips(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "tips-btn",
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "question-icon" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v("标准用户")]),
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "standa-user",
                            "fill-color": "#fff",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.standardUser))]),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: "",
                              trigger: "hover",
                              content: _vm.tipsStr(scope.row),
                              "fallback-placements": [
                                "bottom",
                                "top",
                                "right",
                                "left",
                              ],
                            },
                          },
                          [
                            _vm.showStandardFieldTips(scope.row) ||
                            _vm.showStandardDataTips(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "tips-btn",
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "question-icon" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v("专业用户")]),
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "pro-user",
                            "fill-color": "#fff",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.professionalUser)),
                        ]),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: "",
                              trigger: "hover",
                              content: _vm.tipsStr(scope.row),
                              "fallback-placements": [
                                "bottom",
                                "top",
                                "right",
                                "left",
                              ],
                            },
                          },
                          [
                            _vm.showProFieldTips(scope.row) ||
                            _vm.showProDataTips(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "tips-btn",
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "question-icon",
                                        "fill-color": "white",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v("超级用户")]),
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "super-user",
                            "fill-color": "#E59F25",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "block",
                              "margin-top": "4px",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("（支持多院区）")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.superUser))]),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: "",
                              trigger: "hover",
                              content: _vm.tipsStr(scope.row),
                              "fallback-placements": [
                                "bottom",
                                "top",
                                "right",
                                "left",
                              ],
                            },
                          },
                          [
                            _vm.showSuperFieldTips(scope.row) ||
                            _vm.showSuperDataTips(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "tips-btn",
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "question-icon",
                                        "fill-color": "white",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-footer", { staticClass: "service-intro-footer" }, [
        _c("div", [
          _c("img", {
            staticStyle: {
              width: "20px",
              height: "20px",
              "margin-top": "10px",
              float: "left",
            },
            attrs: { src: require("../../../assets/images/icon.png"), alt: "" },
          }),
          _c("span", { staticStyle: { "padding-left": "5px" } }, [
            _vm._v(_vm._s(_vm.opennessConfig.webSystemLatestVersion)),
          ]),
        ]),
        _c("div", [
          _c("img", {
            staticStyle: {
              width: "20px",
              height: "20px",
              "margin-top": "10px",
              float: "left",
            },
            attrs: {
              src: require("../../../assets/images/beian-icon.png"),
              alt: "",
            },
          }),
          _c(
            "a",
            {
              staticStyle: {
                display: "inline-block",
                "text-decoration": "none",
              },
              attrs: {
                target: "_blank",
                href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620",
              },
            },
            [
              _c(
                "p",
                { staticStyle: { "padding-left": "5px", color: "#939393" } },
                [_vm._v("沪公网安备 31010602006620号")]
              ),
            ]
          ),
        ]),
        _c("div", [
          _c(
            "a",
            {
              staticClass: "baNum",
              staticStyle: { padding: "0" },
              attrs: { href: "https://beian.miit.gov.cn", target: "_blank" },
            },
            [_vm._v("沪ICP备20018067号-2")]
          ),
        ]),
        _c("div", [
          _c(
            "span",
            {
              staticStyle: { "font-size": "18px", "vertical-align": "middle" },
            },
            [_vm._v("©")]
          ),
          _vm._v("2022 上海卫心科技有限公司"),
        ]),
        _c("div", [_vm._v("服务协议")]),
        _c("div", [_vm._v("法律声明及隐私权政策")]),
      ]),
      _c("div", { staticClass: "cust-service-wrap" }, [
        _c(
          "header",
          { staticClass: "cust-service-header" },
          [
            _c("svg-icon", {
              attrs: { "icon-class": "ke-fu", "fill-color": "#668FFF" },
            }),
            _c("span", { staticStyle: { "margin-left": "5px" } }, [
              _vm._v("添加客服咨询"),
            ]),
          ],
          1
        ),
        _c("section", { staticClass: "cust-service-main clearfix" }, [
          _c("img", {
            staticClass: "fl",
            attrs: { src: require("../../../assets//images/kefu-qr.png") },
          }),
          _c("div", { staticClass: "cust-service-main-right" }, [
            _c(
              "p",
              [
                _c("svg-icon", {
                  attrs: {
                    "icon-class": "phone-icon",
                    "fill-color": "#A5BCFF",
                  },
                }),
                _c("span", { staticClass: "text" }, [_vm._v("021-80331222")]),
              ],
              1
            ),
            _c(
              "p",
              [
                _c("svg-icon", {
                  attrs: {
                    "icon-class": "email-user",
                    "fill-color": "#A5BCFF",
                  },
                }),
                _c("span", { staticClass: "text" }, [
                  _vm._v("service@ivisense.com"),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }